<h1 mat-dialog-title>Project Management</h1>
<div mat-dialog-content>Select the project you are working on</div>
<div mat-dialog-actions>
  <mat-radio-group
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
  >
    <mat-radio-button
      (change)="radioChange($event)"
      class="example-radio-button"
      *ngFor="let proj of projects"
      [value]="proj"
      [checked] = "proj.productCollection == appSettings.activeProject?.productCollection"
    >
      {{ proj.project }}
    </mat-radio-button>
  </mat-radio-group>
</div>
