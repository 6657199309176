<div class="window" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div mat-dialog-title>Menu Organizer</div>
    <div cdkDropList [cdkDropListData]="products" class="example-list" (cdkDropListDropped)="drop($event)">
        <div *ngFor="let product of products; let i = index">
            <div class="numbering">{{i+1}}.&nbsp;</div>
            <div class="example-box" [ngStyle]="{'font-weight': (product.folder === 'Group') ? 'bold': 'normal'}"
                cdkDrag>{{product.menuTitle}}</div>
        </div>
    </div>
    <div class="btn-bar">
        <button mat-raised-button color="primary" (click)="onSave()">Save Order</button> &nbsp;
        <button mat-raised-button color="primary" (click)="onReverse()">Reverse Order</button> &nbsp;
        <button mat-raised-button color="primary" (click)="onCancel()">Cancel</button>
    </div>
</div>