import {
  APP_ID,
  assertPlatform,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { Output, EventEmitter } from "@angular/core";
import { Product } from "../data/product";
import { AppSettings } from "../shared/AppSettings";
import { AppSettingsService } from "../shared/appSettings.service";
import { FieldSpec, Project } from "../data/project";
import { BtnDeleteCellRenderer } from "./button-delete-cell-renderer";
import { BtnEnableCellRenderer } from "./button-enable-cell-renderer";
import { MenuOrderCellRenderer } from "./menu-order-cell-renderer";

import { AuthService } from "../services/auth.service";

/*
* https://www.ag-grid.com/angular-data-grid/getting-started/
*/
@Component({
  selector: "app-product-grid",
  templateUrl: "./product-grid.component.html",
  styleUrls: ["./product-grid.component.css"],
})
export class ProductGridComponent implements OnInit {
  @ViewChild("agGrid") public agGrid: AgGridAngular;

  @Output() selectedItemEvent = new EventEmitter<Product>();
  @Output() newProductEvent = new EventEmitter<Product>();
  @Output() productDeleted: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() productStatusToggled: EventEmitter<Product> = new EventEmitter<Product>();

  @Output() gridUpdated = new EventEmitter<Product>();
  @Output() previousProductDirty: EventEmitter<Product> = new EventEmitter<Product>();

  @Input() productRowData: Product[];
  @Input() project: Project;

  private appSettings: AppSettings;
  private isGridDirty: boolean;

  constructor(
    private appSettingsService: AppSettingsService,
    private authService: AuthService
  ) {
    this.appSettings = appSettingsService.getSettings();
  }

  ngOnInit() {
    if (this.appSettings.activeProject?.fields) {
      this.populateColDefs(this.appSettings.activeProject.fields);
      this.agGrid.gridOptions = { tooltipShowDelay: 50 }; // doesnt seem to work
    }
  }

  public reConfigureGrid(project: Project) {
    if (project?.fields)
      this.populateColDefs(project.fields)
  }

  public columnDefs = [];

  public frameworkComponents = {
    btnDeleteCellRenderer: BtnDeleteCellRenderer,
    btnEnableCellRenderer: BtnEnableCellRenderer,
    menuOrderCellRenderer: MenuOrderCellRenderer
  }

  public dateFormatter(params) {
    if (params.value === undefined) {
      return "";
    }
    let date = new Date(params.value).toLocaleString();

    return (date == 'Invalid Date') ? "" : date;
  }

  public userNameFormatter(params) {
    if (typeof params.value !== 'string'){
      return '';
    }
    let name = params.value;
    return name.substring(0, name.indexOf('@'));
  }

  private dateComparator(dateUtc1: string, dateUtc2: string) {
    let d1 = new Date(dateUtc1);
    let d2 = new Date(dateUtc2);

    if (d1 == undefined || d2 === undefined) {
      return 0;
    }

    return d1.getTime() - d2.getTime();
  }

  private populateColDefs(fields: FieldSpec) {
    this.columnDefs = [];

    let project = this.appSettings.activeProject;
    let user = this.authService.getCurrentUser();

    this.columnDefs.push(
      {
        field: "ref",
        cellRenderer: 'btnDeleteCellRenderer',
        pinned: 'right',
        cellClass: 'narrowCell',
        headerName: '',
        editable: false,
        width: '20px',
        cellRendererParams: {
          onClick: this.onDeleteClick.bind(this)
        },        
        resizable: false,
        sortable: false
      });

    this.columnDefs.push(
      {
        field: "menuOrder",
        width: "70px",
        headerName: "Order",
        cellRenderer: 'menuOrderCellRenderer',
        editable: false,
        resizable: true,
        wrapText: true,
        sortable: true
      })

    if (fields.fullTitle) {
      this.columnDefs.push(
        {
          field: "fullTitle",
          headerTooltip: fields.fullTitle.description,
          headerName: fields.fullTitle.label,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.menuTitle) {
      this.columnDefs.push(
        {
          field: "menuTitle",
          headerName: fields.menuTitle.label,
          headerTooltip: fields.menuTitle.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.folder) {
      this.columnDefs.push(
        {
          field: "folder",
          headerName: fields.folder.label,
          headerTooltip: fields.folder.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.shortTitle) {
      this.columnDefs.push(
        {
          field: "shortTitle",
          headerName: fields.shortTitle.label,
          headerTooltip: fields.shortTitle.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    this.columnDefs.push(
      {
        field: 'hotspotId',
        headerName: 'Hotspot ID',
        headerTooltip: '',
        editable: project.locked ? false : true,
        sortable: true,
        resizable: true
      });

    this.columnDefs.push(
      {
        field: 'disabled',
        headerName: 'Active?',
        headerTooltip: '',
        maxWidth: 90,
        sortable: true,
        cellClass: 'narrowCell',
        cellRenderer: 'btnEnableCellRenderer',
        cellRendererParams: {
          onClick: this.onToggleEnabledClick.bind(this)
        }
      });

    if (fields.mainImage) {
      this.columnDefs.push(
        {
          field: "mainImage",
          headerTooltip: fields.mainImage.description,
          headerName: fields.mainImage.label,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.pdf) {
      this.columnDefs.push(
        {
          field: "pdf",
          headerName: fields.pdf.label,
          headerTooltip: fields.pdf.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.slide2) {
      this.columnDefs.push(
        {
          field: "slide2",
          headerName: fields.slide2.label,
          headerTooltip: fields.slide2.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    this.columnDefs.push({
      rowDrag: false,
      field: "ref",
      headerTooltip: '',
      editable: user.role === 'admin' && !project.locked,
      resizable: true,
      wrapText: false,
      sortable: true
    });

    this.columnDefs.push(
      {
        field: "audit.updatedOn",
        headerName: "Last Updated On",
        valueFormatter: this.dateFormatter,
        comparator: this.dateComparator,
        editable: false,
        resizable: true,
        wrapText: false,
        sortable: true,
        width: "140px"
      })

    this.columnDefs.push(
      {
        field: "audit.updatedBy",
        valueFormatter: this.userNameFormatter,
        headerName: "Last Updated By",
        editable: false,
        resizable: true,
        wrapText: true,
        sortable: true,
        width: "140px"
      })

    if (fields.slide3) {
      this.columnDefs.push(
        {
          field: "slide3",
          headerName: fields.slide3.label,
          headerTooltip: fields.slide3.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.slide4) {
      this.columnDefs.push(
        {
          field: "slide4",
          headerName: fields.slide4.label,
          headerTooltip: fields.slide4.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.slide5) {
      this.columnDefs.push(
        {
          field: "slide5",
          headerName: fields.slide5.label,
          headerTooltip: fields.slide5.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.slide6) {
      this.columnDefs.push(
        {
          field: "slide6",
          headerTooltip: fields.slide6.description,
          headerName: fields.slide6.label,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.slide7) {
      this.columnDefs.push(
        {
          field: "slide7",
          headerName: fields.slide7.label,
          headerTooltip: fields.slide7.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.slide8) {
      this.columnDefs.push(
        {
          field: "slide8",
          headerName: fields.slide8.label,
          headerTooltip: fields.slide8.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.slide9) {
      this.columnDefs.push(
        {
          field: "slide9",
          headerName: fields.slide9.label,
          headerTooltip: fields.slide9.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    if (fields.slide10) {
      this.columnDefs.push(
        {
          field: "slide10",
          headerName: fields.slide10.label,
          headerTooltip: fields.slide10.description,
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    };

    this.columnDefs.push(
      {
        field: "height",
        width: "80px",
        headerName: "Height",
        editable: project.locked ? false : true,
        resizable: true,
        wrapText: true,
        sortable: true
      })

    this.columnDefs.push(
      {
        field: "width",
        width: "80px",
        headerName: "Width",
        editable: project.locked ? false : true,
        resizable: true,
        wrapText: true,
        sortable: true
      })

    if (user.role === 'admin') {
      this.columnDefs.push(
        {
          field: "padding",
          width: "80px",
          headerName: "Padding",
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })

      this.columnDefs.push(
        {
          field: "logoAlign",
          width: "90px",
          headerName: "Logo Align",
          editable: project.locked ? false : true,
          resizable: true,
          wrapText: true,
          sortable: true
        })
    }

  }

  onRefreshProducts() {
    this.bindProducts();
    this.agGrid.api.sizeColumnsToFit();
  }

  public refresh() {
    this.agGrid.api.refreshCells();
  }

  public bindProducts() {
    console.log("binding grid to product data")

    if (!this.appSettings.activeProject)
      return;

    this.populateColDefs(this.appSettings.activeProject.fields);
    this.agGrid.api.refreshHeader();

    //this.agGrid.api.setColumnDefs([]);

    if (this.productRowData?.length > 0) {

      this.agGrid.api.setRowData(this.productRowData);

      this.agGrid.api.refreshCells();

    } else {
      this.agGrid.api.setRowData(this.productRowData);
      this.selectedItemEvent.emit(undefined);
    }
  }

  public get selectedIndex(): number {
    var selectedRow = this.agGrid.api.getSelectedNodes()[0];
    let index = selectedRow.childIndex;
    return index;
  }

  public set selectedIndex(index: number) {
    this.agGrid.api.selectIndex(index, false, false);
  }

  public clearSortOrder() {
    this.agGrid.gridOptions.columnApi.applyColumnState({
      defaultState: { sort: null }
    }
    )
  }

  public updateCurrentRow(product: Product) {
    const selectedNode = this.agGrid.api.getSelectedNodes()[0];

    if (selectedNode)
      selectedNode.setData(product);
  }

  onFirstDataRendered(event) {
  }

  onCellValueChanged(event) {
    this.isGridDirty = true;
    const selectedNode = this.agGrid.api.getSelectedNodes()[0];

    const product = selectedNode?.data;

    if (product) {
      this.gridUpdated.emit(product);
    }
  }

  private onDeleteClick(params: any) {
    if (
      confirm(
        "Are you sure you want to permanently delete this product? This cannot be undone."
      )
    ) {
      this.productDeleted.emit(params.node.data);
    }
  }

  private onToggleEnabledClick(params: any) {
    if (this.project.locked) {
      return;
    }

    if (!params.node.data.disabled) {
      params.node.data.disabled = true;
    }
    else {
      params.node.data.disabled = !params.node.data.disabled;
    }

    this.productStatusToggled.emit(params.node.data);
  }

  // fires for the selected and deselected row
  onRowSelected(event) {
    const selectedNode = this.agGrid.api.getSelectedNodes()[0];

    const product = selectedNode?.data;

    if (product) {
      this.selectedItemEvent.emit(product);
    }

    if (!event.node.selected) {
      // deselected 
      if (this.isGridDirty) {
        this.previousProductDirty.emit(event.node.data);
        this.isGridDirty = false;
      }
    }
  }
}
