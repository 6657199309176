import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../data/product';
import { Project } from '../data/project';

@Component({
  selector: 'app-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.css']
})
export class ProductPreviewComponent implements OnInit {

  constructor() { }

  @Input() public product: Product;
  @Input() project: Project;

  public get styledHtml() {
    const styleHtml = `<div class="panoPreviewTitle">${this.product.fullTitle}</div><div class="panoPreviewText">${this.product.html}</div>`;
    return styleHtml;
  }

  ngOnInit(): void {
    const css = this.project.previewCss;
    const id = "panoPreviewStyling";

    const styleElement = document.getElementById(id);

    if (styleElement)
      styleElement.remove();

    const head = document.getElementsByTagName('head')[0];

    const style = document.createElement('style');
    style.id = id;
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
  }
}
