import { Injectable } from "@angular/core";
import { AppSettings } from "./AppSettings";

/**
 * singleton
*/
@Injectable({
  providedIn: 'root'
})

export class AppSettingsService {

  private settings : AppSettings = new AppSettings();


  getSettings(): AppSettings {
    return this.settings;
  }
}
