<div class="component-container" fxLayout="row" fxLayoutAlign="center center">
  <form class="form">
    <!-- User Inputs  -->
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input
        autofocus
        matInput
        type="email"
        [(ngModel)]="email"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Password</mat-label>
      <input
        matInput
        type="password"
        [(ngModel)]="password"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>

    <!-- Login & Submit buttons -->
    <button
      mat-raised-button
      type="button"
      class="login"
      color="primary"
      (click)="onLoginClicked()"
      label="Login"
    >
      <span> Login </span>
    </button>
  </form>
</div>
