import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
// For MDB Angular Free
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent {
  form: FormGroup;

  public email: string;
  public password: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  public onLoginClicked() {
    if (this.email && this.password) {
      this.authService.login(this.email, this.password).subscribe(
        (response: any) => {
          let token = response.jwtBearerToken;

          if (token) {
            this.router.navigateByUrl("/products")
          } else {
            this.toastr.error(
              "Login failed. Please check your credentials and try again",
              "Login Failure"
            );
          }
        },
        (error: any) => {
          this.toastr.error(
            "Login failed. Please check your credentials and try again",
            "Login Failure"
          );
        }
      );
    }
  }
}
