
    <mat-toolbar class="app-toolbar">
        <div>
            <button *ngIf="isLoggedIn()" mat-button [matMenuTriggerFor]="menu">
                <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button *ngIf="hasMultipleProjects()" mat-menu-item (click)="openProjectSettingModal()">Switch
                    Project</button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="refreshProject()">Refresh Project </button>
                <button mat-menu-item (click)="backupProject()">Backup Project</button>
                <button *ngIf="authService.getCurrentUser()?.role == 'admin'" mat-menu-item
                (click)="uploadProject()">Restore Project</button>
                <span *ngIf="isLoggedIn() && appSettings?.activeProject?.productsXmlDownloadable === true">
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="downloadProducts()">Download Project XML</button>

                </span>
              

                <mat-divider ></mat-divider>
                <button *ngIf="!appSettings?.activeProject?.locked" mat-menu-item (click)="organizeMenu()"> Menu Organizer </button>
                <mat-divider></mat-divider> 
                <button mat-menu-item (click)="openUserGuide()">User Guide </button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="logOut()">Log Out</button>
            </mat-menu>
        </div>
        <div class="title">
            <span class="appTitle"><img class="logo" height="50px" width="100px" src="assets/images/LogoBlackSmall.png">
                CMS </span>
            <span class="version">(v1.3)</span>
            <span *ngIf="isLoggedIn()" class="user">
                {{user?.email}}
            </span>
            <span *ngIf="isLoggedIn() && appSettings?.activeProject?.locked">
                (PROJECT LOCKED)
            </span>
        </div>
        <div class="projDescriber">
            <span *ngIf="isLoggedIn()">
                <span>{{appSettings?.activeProject?.project}}</span>
                <span *ngIf="user?.role == 'admin'"><br> {{appSettings?.activeProject?.productCollection}}</span>
            </span>
        </div>
    </mat-toolbar>
<ngx-spinner></ngx-spinner>

<!-- This is where feature/page module content will be displayed -->
<div class="main">
    <router-outlet>
    </router-outlet>
</div>
<div *ngIf="env" class="ribbon">{{env}}</div>