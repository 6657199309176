import {
  Component,
  OnInit,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Product } from "../data/product";
import { Project } from "../data/project";

import { AppSettingsService } from "../shared/appSettings.service";
import { AppSettings } from "../shared/AppSettings";
import { AuthService } from "../services/auth.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

import { html_beautify } from "js-beautify";

@Component({
  selector: "app-product-edit-form",
  templateUrl: "./product-edit-form.component.html",
  styleUrls: ["./product-edit-form.component.css"],
})
export class ProductEditFormComponent implements OnInit {
  //https://stackoverflow.com/questions/38571812/how-to-detect-when-an-input-value-changes-in-angular
  constructor(
    public authService: AuthService,
    private appSettingsService: AppSettingsService,
    public dialog: MatDialog
  ) {
    this.appSettings = appSettingsService.getSettings();
    this.postRenderFunc = this.postRenderFunc.bind(this);
    this.preRenderFunc = this.preRenderFunc.bind(this);
  }
  @Input() product: Product;
  @Input() project: Project;
  @Input()
  set dirty(dirty: boolean) {
    if (dirty)
      this.form?.form.markAsDirty();
    else
      this.form?.form.markAsPristine();
  }

  @Output() productDeleted: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() productUpdated: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() previousProductDirty: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() collapseForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("form", {}) form: NgForm;

  public appSettings: AppSettings;
  public isCollapsed: boolean = false;

  options = {
    showPreviewPanel: false,
    resizable: false,
    showBorder: true,
    hideIcons: [
      "Italic",
      "Heading",
      "Reference",
      "Link",
      "FullScreen",
      "Image",
      "Ol",
      "Code",
    ],

  };

  ngOnInit() {
    console.log('edit form int')
  }

  ngAfterViewInit() {
    this.form.control.valueChanges.subscribe(() => {
      console.log("valueChanges..");
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.form && this.form.dirty && !this.project.locked) {
      console.log("form is dirty");
      this.previousProductDirty.emit(changes.product.previousValue)
      this.form.form.markAsPristine();
    }

    console.log("ngOnChanges..");
  }

  public history() {

    let audit = this.product?.audit;
    let text = '<table><tr><td>Created:&nbsp;</td><td><i>';
    text += `${this.formatUserName(audit?.createdBy)} ${this.formatDate(audit?.createdOn)}`;

    text += '</i></td></tr><tr><td>Last Updated:&nbsp; </td><td><i>';
    text += `${this.formatUserName(audit?.updatedBy)} ${this.formatDate(audit?.updatedOn)}`;

    text += '</i></td></tr></table>'
    return text;
  }

  formatUserName(email) {
    if (email && typeof email === 'string' && email !== '') {
      return email.substring(0, email.indexOf('@'));
    }
    else {
      return 'Not Available';
    }
  }

  onFocusOutEvent($event) {
    if (this.form && this.form.dirty && !this.project.locked) {
      this.productUpdated.emit(this.product);
    }
  }

  onDeleteProduct() {
    if (
      confirm(
        "Are you sure you want to delete this product? This cannot be undone."
      )
    ) {
      this.productDeleted.emit(this.product);
    }
  }

  showForm(value: boolean) {
    this.isCollapsed = !value;
    this.collapseForm.emit(!value);
  }

  /*
  * this will not effect ngModel's value, just rendered value
  */
  preRenderFunc(content: string) {
    return content;
  }
  /*
  * Change the html souce code generated by marked before update the dom
  */
  postRenderFunc(html: string) {
    html = html.replace(/\-\/\-/g, '&#8209;');
    html = html.replace(/~/g, '&shy;');
    html = html.replace(/--/g, '&nbsp;');

    // extract the html before we add the preview styling
    if (this.product) {
      this.product.html = html_beautify(html, { indent_size: 8, space_in_empty_paren: true });
    }

    const styledHtml = "<style> " + this.project?.previewCss + "</style>" + "<div class='panoPreview'>" + html + "</div>";
    return styledHtml;
  }

  formatDate(utcDate) {
    if (!utcDate) {
      return '';
    }
    return '(' + new Date(utcDate).toLocaleString() + ')';
  }

}
