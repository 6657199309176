import {
  APP_ID,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Product } from "./data/product";

import { AppSettingsService } from "./shared/appSettings.service";

import { ToastrService } from "ngx-toastr";
import { AppSettings } from "./shared/AppSettings";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import {Title} from "@angular/platform-browser";

import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { ProjectSettingsModalComponent } from "./project-settings-modal/project-settings-modal.component";
import { Router, RouterOutlet } from "@angular/router";
import { ProductMainComponent } from "./product-main/product-main.component";

import { ProductService } from "./services/product.service";
import { FileUploadModalComponent } from "./file-upload-modal/file-upload-modal.component";
import { AuthService } from "./services/auth.service";
import { User } from "./shared/user";
import { MenuOrganizerComponent } from "./menu-organizer/menu-organizer.component";

@Component({
  selector: "my-app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  product: Product; // currently selected product

  private _env = "PROD";
  public faCoffee = faCoffee;

  @ViewChild(RouterOutlet, {}) outlet: any;

  public appSettings: AppSettings;

  public get user(): User {
    const user = this.authService.getCurrentUser();
    return user;
  }

  constructor(
    private appSettingsService: AppSettingsService,
    public authService: AuthService,
    private productService: ProductService,
    private titleService: Title,
    private toastr: ToastrService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.appSettings = appSettingsService.getSettings();

    if (this._env  === 'PROD'){
      this.titleService.setTitle('Look.insite CMS')
    }
    else{
      this.titleService.setTitle('Look.insite CMS (Staging)');
    }
  }

  public get env(): string {
    return this._env == 'PROD'
      ? undefined
      : this._env;
  }

  openProjectSettingModal() {
    this.dialog.closeAll();
    let options: MatDialogConfig = {
      panelClass: 'moveModal' /**TODO: revisit needing to do this */
    };
    
    const dialogRef = this.dialog.open(ProjectSettingsModalComponent, options);

    dialogRef.afterClosed().subscribe((result) => {
      this.loadProducts(result)
    });
  }

  private loadProducts(result){
    if (this.outlet && this.outlet.component) {
      (this.outlet.component as ProductMainComponent).loadProducts(); 

      if (result.project){
        this.toastr.success('Switched project to ' + result.project);
      }
      else{
        this.toastr.success('Refreshed all project data from server');
      }

    }
  }

  public openUserGuide(){
    window.open('https://look-insite.de/Resources/Guides/Look.insite_User_Guide.pdf', 'new');
  }

  public uploadProject() {
    let options: MatDialogConfig = {
      panelClass: 'moveModal' /**TODO: revisit needing to do this */
    };

    this.dialog.closeAll();

    const dialogRef = this.dialog.open(FileUploadModalComponent,{
      panelClass: 'moveModal' /**TODO: revisit needing to do this */
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (this.outlet && this.outlet.component && result) {
        (this.outlet.component as ProductMainComponent).loadProducts();
        this.toastr.success("Project updated", 'Server Update');
      }
    });
  }

  public refreshProject() {
    this.loadProducts('');
  }

  public organizeMenu(){
    this.dialog.closeAll();

    let products = (this.outlet.component as ProductMainComponent).grid.productRowData; 

    let options: MatDialogConfig = {
      data: products,
      hasBackdrop: true,
      panelClass: 'moveMenuOrganizer'
    };

    const dialogRef = this.dialog.open(MenuOrganizerComponent,  options);

    dialogRef.afterClosed().subscribe((result) => {
      if (this.outlet && this.outlet.component && result) {
        (this.outlet.component as ProductMainComponent).loadProducts();
        this.toastr.success("Menu order saved", 'Server Update');
      }
    });
  }

  public backupProject() {
    this.productService.getProducts(true).subscribe((x: any) => {
      console.log(x);

      x.forEach((p) => {
        delete p._id;
      });

      const blob = new Blob([JSON.stringify(x, null, 2)], {
        type: "application/json",
      });

      const a = document.createElement("a");
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = this.appSettings.activeProject.productCollection + ".json";
      a.click();
      URL.revokeObjectURL(objectUrl);
      this.toastr.success("Project backup file downloaded");
    });
  }

  public downloadProducts(){
    this.productService.getKrpanoProductsXml().subscribe((xml: string) => {

      console.log("KRPano XML:")
      console.log(xml);

      const blob = new Blob([xml], {
        type: "application/xml",
      });

      const a = document.createElement("a");
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = this.appSettings.activeProject.productCollection + ".xml";
      a.click();
      URL.revokeObjectURL(objectUrl);
      this.toastr.success("Project xml file downloaded");
    });

  }

  public logOut() {
    localStorage.removeItem("cms_id_token");
    this.router.navigateByUrl("/login");
  }

  public isLoggedIn(): boolean {
    return localStorage.getItem("cms_id_token") !== null;
  }

  public hasMultipleProjects(): boolean {
    if (!this.user) {
      return false;
    }
    else {
      return this.user.projects.length > 1;
    }
  }

  ngOnInit() {
    console.log('init app component');
  }
}
