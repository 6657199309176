import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "../shared/AppSettings";
import { AppSettingsService } from "../shared/appSettings.service";
import { Observable } from "rxjs";
import { Product } from "../data/product";
import { map, filter, scan, tap } from "rxjs/operators";
import { MenuOrder } from "../menu-organizer/menuOrder";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  private appSettings: AppSettings;

  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService,
  ) {
    this.appSettings = appSettingsService.getSettings();
  }

  /*
  * adds a new product
  * takes the productRowData because it determines a new ref based on the last one created
  */
  public addProduct(productRowData: any[]): Observable<Product> {
    console.log('adding product')
    let product = this.createNewProduct(productRowData);

    let headers = new HttpHeaders();
    headers = headers
      .set("project", this.appSettings.activeProject.productCollection)
      .set("responseType", "text");

    let url = this.appSettings.baseUrl + "/products";
    console.log(url);

    return this.http
      .post<Product>(url, product, { headers: headers, responseType: "json" })
      .pipe(tap(this.reponseLogger)) as Observable<Product>;
  }

  public cloneProduct(product: Product, productRowData: any[]) {

    let clonedProduct = { ...product };
    clonedProduct._id = undefined;
    clonedProduct.ref = clonedProduct.ref + '_copy';

    let headers = new HttpHeaders();
    headers = headers
      .set("project", this.appSettings.activeProject.productCollection)
      .set("responseType", "text");

    let url = this.appSettings.baseUrl + "/products";
    console.log(url);

    return this.http
      .post<Product>(url, clonedProduct, { headers: headers, responseType: "json" })
      .pipe(tap(this.reponseLogger)) as Observable<Product>;

  }

  public saveMenuOrder(products: Product[]) {
    let headers = new HttpHeaders();
    headers = headers.set("project", this.appSettings.activeProject.productCollection);

    let url = this.appSettings.baseUrl + "/products/menuOrder";
    console.log(url);

    let position = 1;
    let productOrder: MenuOrder[] = products.map(x => ({
      _id: x._id,
      order: position++,
    }
    ));

    return this.http
      .put(url, productOrder, { headers: headers })
      .pipe(tap(this.reponseLogger));

  }

  public saveProduct(product: Product) {
    let headers = new HttpHeaders();
    headers = headers.set("project", this.appSettings.activeProject.productCollection);

    if (product.disabled) {
      product.menuOrder = 999;
    }

    let productid = product._id;

    let url = this.appSettings.baseUrl + "/products/" + productid;
    console.log(url);

    return this.http
      .put(url, product, { headers: headers })
      .pipe(tap(this.reponseLogger));
  }

  public getProducts(isDownload: boolean = false): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("project", this.appSettings.currentProductCollection);

    let url = this.appSettings.baseUrl + "/products";
    console.log(url);

    return this.http
      .get(url, {
        headers: headers
      })
      .pipe(tap(this.reponseLogger));
  }

  public uploadProducts(products: Product[]): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("project", this.appSettings.activeProject.productCollection);

    return this.http.post(
      this.appSettings.baseUrl + "/products/bulk",
      products,
      {
        headers: headers
      }
    );
  }

  public deleteProduct(product: Product): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("project", this.appSettings.activeProject.productCollection);

    let url = this.appSettings.baseUrl + "/products/" + product._id;
    console.log(url);

    return this.http
      .delete(url, {
        headers: headers
      })
      .pipe(tap(this.reponseLogger));
  }

  public getKrpanoProductsXml() : Observable<string>{
    let url = this.appSettings.baseUrl + "/krpano/" + this.appSettings.currentProductCollection + "/products";
    console.log(url);

    const headers = new HttpHeaders({ 'Content-Type': 'text/xml' });
    headers.append('Accept', 'text/xml');
    headers.append('Content-Type', 'text/xml');

    return this.http
      .get(url,{ responseType:'text'})
      .pipe(tap(this.reponseLogger));
  }

  private reponseLogger(response) {
    console.log('server response: ');
    console.log(response)
  }

  private createNewProduct(productRowData: any[]): Product {

    let prod: Product = {
      _id: "NEW",
      ref: this.generateNewProductRef(productRowData),
      hotspotId: "",
      shortTitle: "",
      menuTitle: "",
      fullTitle: "- New Product -",
      mainImage: "",
      disabled: false,
      folder: "",
      height: 30,
      width: 370,
      infoPanel: "",
      logoAlign: "bottomleft",
      pdf: "",
      slide2: "",
      slide3: "",
      slide4: "",
      slide5: "",
      slide6: "",
      slide7: "",
      slide8: "",
      slide9: "",
      slide10: "",
      html: "",
      padding: "21 60 0 40",
      audit: {},
      menuOrder: 999
    };

    delete prod._id;

    return prod;
  }

  generateNewProductRef(productRowData: any[]): string {
    let maxId = 0;
    if (productRowData?.length > 0) {
      let allRefs = (productRowData as Product[]).map(x => x.ref).filter(x => x !== undefined);

      let prodIds = allRefs
        .map(x => parseInt(x.slice(x.length - 3)))
        .filter(x => !isNaN(x));

      maxId = Math.max(...prodIds);

      if (!isFinite(maxId)) {
        maxId = 0;
      }
    }

    let newId = maxId + 1;
    let paddedId = String(newId).padStart(3, '0');

    return this.appSettings.productRefTemplate + paddedId;
  }
}