import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "../shared/AppSettings";
import { AppSettingsService } from "../shared/appSettings.service";
import { Observable, throwError } from "rxjs";
import { Product } from "../data/product";
import { map, filter, scan, tap, catchError } from "rxjs/operators";
import { Project } from "../data/project";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  private appSettings: AppSettings;

  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService,
  ) {
    this.appSettings = appSettingsService.getSettings();
  }

  public getProjects(): Observable<Project[]> {
    
    return this.http
      .get<Project[]>(this.appSettings.baseUrl + "/projects")
      .pipe(
        tap((x) => console.log(x)),
        catchError((x) => {
          console.log(x);
          return throwError(x.message)
        }));
  }

  public getProject(collection: string): Observable<Project> {
    return this.http
      .get<Project>(this.appSettings.baseUrl + "/projects/" + collection)
      .pipe(tap((x) => console.log(x)),
        catchError((x) => {
          console.log(x);
          return throwError(x.message)
        }));
  }
}
