import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "../shared/AppSettings";
import { AppSettingsService } from "../shared/appSettings.service";
import { tap, map } from "rxjs/operators";
import { validateHorizontalPosition } from "@angular/cdk/overlay";
import { User } from "../shared/user";
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private appSettings: AppSettings;

  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {
    this.appSettings = appSettingsService.getSettings();
  }

  public login(email: string, password: string) {

    return this.http
      .post(this.appSettings.baseUrl + "/login", { email, password })
      .pipe(
        tap({
          next: (val) => {
            console.log(val);
            const token = (val as any).jwtBearerToken;
            localStorage.setItem("cms_id_token", token);

            const user = this.getCurrentUser();

            if (user.projects.length === 1){
              this.appSettings.currentProductCollection = user.projects[0]; 
            }

            return val;
          },
          error: (error) => {
            console.log(error);
            throw error;
          },
        })
      );
  }

  // get info on the current authenticated user
  public getCurrentUser(): User {
    const token = localStorage.getItem("cms_id_token");

    if (!token) {
      return null;
    } else {
      const jwt = this.getDecodedAccessToken(token);

      const user = new User();
      user.email = jwt.user;
      user.projects = jwt.projects;
      user.role = jwt.role;

      return user;
    }
  }

  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  public logout() {}
}
