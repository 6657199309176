import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../data/product';
import { Project } from '../data/project';
import { ProductService } from '../services/product.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// https://material.angular.io/cdk/drag-drop/overview
@Component({
  selector: 'app-menu-organizer',
  templateUrl: './menu-organizer.component.html',
  styleUrls: ['./menu-organizer.component.css']
})
export class MenuOrganizerComponent implements OnInit {

  constructor(
    private productService: ProductService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<MenuOrganizerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.products = data.filter(x => !x.disabled)
                        .sort((x, y) => x.menuOrder - y.menuOrder);
  }

  public products: Product[];

  ngOnInit(): void {
    // this.productService.getProducts().subscribe({
    //   next: (products: Product[]) => {
    //     console.log(products);

    //     this.products = products.filter(x=> !x.disabled)
    //                             .sort((x,y) => x.menuOrder - y.menuOrder);
    //   },
    //   error: (error) => {
    //     this.toastr.error("products could not be fetched", "Server Error");

    //     console.error(error);
    //   },
    // });
  }

  public drop(event) {

    let newIndex = event.currentIndex;
    let prevIndex = event.previousIndex;

    this.products.splice(newIndex, 0, this.products.splice(prevIndex, 1)[0]);

    console.log(event)
  }

  public async onSave() {
    this.productService.saveMenuOrder(this.products)
      .subscribe(() => {
        console.log('saved menu');
        this.dialogRef.close(true)
      }, (error) => {
        console.log(error);
      });
  }

  public onReverse(){
    this.products.reverse();
  }

  public onCancel() {
    this.dialogRef.close(false);
  }

}
