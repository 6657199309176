import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService,  
                private toastr: ToastrService,) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinner.show();
    return next.handle(request).pipe(
      finalize(() => {
        this.spinner.hide();
      }), 
      catchError((error: HttpErrorResponse) => {

        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {

          // client-side error
          errorMessage = `Error: ${error.error.message}`;

        } else {
          // server-side error
          if (error.error == 'projectLocked'){
            errorMessage = 'This project has been locked and products cannot be updated at the moment. Reloading application..'
            window.location.reload()
          }
          else{
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
        }
        this.toastr.error(errorMessage);

        return throwError(errorMessage);

      })

    ) as Observable<HttpEvent<any>>;
  }
}