import {
  APP_ID,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";

// For MDB Angular Free
import { ToastrService } from "ngx-toastr";

import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ProductGridComponent } from "../product-grid/product-grid.component";
import { AppSettings } from "../shared/AppSettings";
import { AppSettingsService } from "../shared/appSettings.service";
import { Product } from "../data/product";
import { ProjectService } from "../services/project.service";
import { Project } from "../data/project";
import { ProductService } from "../services/product.service";
import { ProductPreviewComponent } from "../product-preview/product-preview.component";
import { Overlay } from '@angular/cdk/overlay';

// https://www.ag-grid.com/angular-data-grid/data-update-single-row-cell/
@Component({
  selector: "my-app",
  templateUrl: "./product-main.component.html",
  styleUrls: ["./product-main.component.css"],
})
export class ProductMainComponent implements OnInit {
  public product: Product; // currently selected product
  public project: Project; // current project

  @ViewChild(ProductGridComponent, {}) grid: ProductGridComponent;

  private previewDialogRef: any = null;

  public appSettings: AppSettings;
  public isDirty: boolean = false;

  constructor(
    private appSettingsService: AppSettingsService,
    private projectService: ProjectService,
    private productService: ProductService,
    private toastr: ToastrService,
    private overlay: Overlay,
    public dialog: MatDialog
  ) {
    this.appSettings = appSettingsService.getSettings();
  }

  ngOnInit() {
    console.log('init main screen');

    this.projectService.getProject(this.appSettings.currentProductCollection)
      .subscribe(
        (project: Project) => {
          this.appSettings.activeProject = project;
          this.project = project;
          this.loadProducts();
          this.toastr.success('Products refreshed from server', 'Server Update');
          this.isDirty = false;
        }
      );
  }

  public loadProducts() {
    this.project = this.appSettings.activeProject;
    this.grid?.reConfigureGrid(this.project);

    return this.productService.getProducts()
      .subscribe((x: any) => {
        this.grid.productRowData = x;
        this.grid.bindProducts();
        this.grid.selectedIndex = 0;
        this.isDirty = false;
      });
  }

  onDeleteProduct() {
    if (
      confirm(
        "Are you sure you want to delete this product? This cannot be undone."
      )
    ) {
      this.onProductDeleted(this.product);
    }
  }

  onSaveProduct() {
    this.saveProduct(this.product);
  }

  onItemSelected(product: Product) {
    this.product = product;

    if (this.previewDialogRef !== null) {

      this.previewDialogRef.updateSize(product.width + 'px');
      const previewComponent = <ProductPreviewComponent>this.previewDialogRef.componentInstance;

      if (!previewComponent) {
        this.previewDialogRef = null;
        return;
      }

      previewComponent.product = this.product;
      previewComponent.project = this.project;
    }
  }

  onRefreshProducts() {
    this.ngOnInit();
  }

  onAddProduct() {
    this.productService.addProduct(this.grid.productRowData)
      .subscribe((product: Product) => {

        console.log("adding new product");

        this.grid.productRowData.unshift(product);
        this.grid.clearSortOrder();                                                                  ;
        this.grid.bindProducts();

        this.grid.selectedIndex = 0;
        this.product = product;
      });
  }

  onCloneProduct(){
    let ok = window.confirm(`Are you sure you want to make a duplicate of the selected product '${this.product.ref}' ? `);

    if (!ok)
      return;

    this.productService.cloneProduct(this.product, this.grid.productRowData)
      .subscribe((product: Product) => {

        this.toastr.success(`Product '${this.product.ref}' duplicated as '${product.ref}'`, "New Product");

        this.grid.productRowData.unshift(product);
        this.grid.bindProducts();

        this.grid.selectedIndex = 0;
        this.product = product;
      });
  }

  onProductDeleted(product: Product) {
    this.productService.deleteProduct(product)
      .subscribe({
        next: (data) => {
          this.toastr.success(
            "Product " + product.ref + " deleted!",
            "Server Update"
          );

          this.grid.productRowData = this.grid.productRowData.filter(x => x._id != product._id);

          let nexIndex = this.grid.selectedIndex++;
          this.grid.bindProducts();
          if (nexIndex > this.grid.productRowData.length - 1) {
            nexIndex = this.grid.productRowData.length - 1;
          }

          this.grid.selectedIndex = nexIndex;
          this.grid.agGrid.api.ensureIndexVisible(this.grid.selectedIndex);

        },
        error: (error) => {
          this.toastr.error("Product could not be deleted!", "Server Error");

          console.error(error);
        },
      });
  }

  onPreviousProductDirty(product: Product) {
    this.saveProduct(product);
    this.isDirty = false;
  }

  onProductStatusToggled(product: Product) {
    console.log(`onProductStatusToggled() - entered`);

    this.saveProduct(product);
  }

  onProductUpdated(product: Product) {
    console.log(`handling update of product ${product.ref}, server updated at ${product.audit?.updatedOn}`);

    // update the grid data 
    let index = this.grid.productRowData.findIndex(x => x._id == product._id);
    this.grid.productRowData[index] = product;

    this.isDirty = true;
    this.grid.refresh();

    this.onItemSelected(product);
  }

  onPreviewProduct() {
    if (this.previewDialogRef !== null) {
      this.dialog.closeAll();
      return;
    };

    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    // https://stackoverflow.com/questions/47510888/how-can-i-make-a-matdialog-draggable-angular-material
    let options: MatDialogConfig = {
      width: this.product.width + "px",
      maxWidth: "1000px",
      hasBackdrop: false,
      scrollStrategy,
      panelClass: 'movePreview'

    };

    this.previewDialogRef = this.dialog.open(ProductPreviewComponent, options) as any;

    this.previewDialogRef.afterClosed().subscribe(x => {
      this.previewDialogRef = null;
    });

    (<ProductPreviewComponent>this.previewDialogRef.componentInstance).product = this.product;
    (<ProductPreviewComponent>this.previewDialogRef.componentInstance).project = this.project;
  }

  onColumnValueChanged(event) {
    this.isDirty = true;
  }

  onFormCollapse(isCollapsed) {
    if (isCollapsed) {
      let list: any = document.getElementsByClassName("listPanel")[0];
      list.style.width = '100%';
    }
  }

  onSave() {
    this.saveProduct(this.product);
  }

  private saveProduct(product: Product) {
    this.productService
      .saveProduct(product)
      .subscribe((updatedProduct: Product) => {

        product.audit = updatedProduct.audit;
        this.toastr.success(`${product.ref} saved`, 'Server Update');
        this.isDirty = false;

        if (product.disabled){
          this.grid.refresh();
        }
      }
      );
  }

  private hookUpResize() {
    // Query the element
    const resizer = document.getElementById('dragMe');
    const leftSide = resizer.previousElementSibling as any;
    const rightSide = resizer.nextElementSibling as any;

    const mouseUpHandler = function () {
      resizer.style.removeProperty('cursor');
      document.body.style.removeProperty('cursor');

      leftSide.style.removeProperty('user-select');
      leftSide.style.removeProperty('pointer-events');

      rightSide.style.removeProperty('user-select');
      rightSide.style.removeProperty('pointer-events');

      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - x;
      const dy = e.clientY - y;

      let parentElem = resizer.parentNode as HTMLElement

      const newLeftWidth = ((leftWidth + dx) * 100) / parentElem.getBoundingClientRect().width;
      leftSide.style.width = `${newLeftWidth}%`;
    };
    // Handle the mousedown event
    // that's triggered when user drags the resizer
    const mouseDownHandler = function (e) {
      // Get the current mouse position
      x = e.clientX;
      y = e.clientY;
      leftWidth = leftSide.getBoundingClientRect().width;

      // Attach the listeners to `document`
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    // The current position of mouse
    let x = 0;
    let y = 0;

    // Width of left side
    let leftWidth = 0;

    // Attach the handler
    resizer.addEventListener('mousedown', mouseDownHandler);
  }
}