<span class="expander">
    <mat-icon class="btnIcons" matTooltipPosition="after" title="Click to show form" (click)="showForm(true)"
        [ngClass]="{'hideForm' : !isCollapsed}"> arrow_forward_ios</mat-icon>
    <mat-icon class="btnIcons" title="Click to hide form" (click)="showForm(false)"
        [ngClass]="{'hideForm' : isCollapsed}">arrow_back_ios</mat-icon>
</span>
<span>
    <div class="containerForm">
        <!--<mat-icon *ngIf="!isCoFllapsed" class="infoPanel" > info</mat-icon>-->
        <div>
            <form #form="ngForm" [ngClass]="{'hideForm' : isCollapsed}">

                <mat-tab-group animationDuration="0ms" dynamicHeight mat-align-tabs="start">
                    <mat-tab label="Text">
                        <p>
                        <div class="containerTitle">
                            <mat-form-field *ngIf="project?.fields?.fullTitle" appearance="fill" class="item1">
                                <mat-label>{{project.fields.fullTitle.label}} </mat-label>
                                <input matInput (focusout)="onFocusOutEvent($event)" name="fullTitle"
                                    [(ngModel)]="product && product.fullTitle"
                                    [readonly]="!product || project?.locked" />
                            </mat-form-field>
                        </div>
                        <div class="editor" [ngClass]="{'disableEditor': project?.locked}">
                            <md-editor (focusout)="onFocusOutEvent($event)" name="Content"
                                [(ngModel)]="product && product.infoPanel" [postRender]="postRenderFunc"
                                [preRender]="preRenderFunc" [options]="options">
                            </md-editor>
                            <input type="hidden" />
                        </div>
                    </mat-tab>
                    <mat-tab label="Labels">
                        <p></p>
                        <mat-form-field *ngIf="project?.fields?.menuTitle" appearance="fill">
                            <mat-label>{{project.fields.menuTitle.label}} </mat-label>
                            <input matInput (focusout)="onFocusOutEvent($event)" name="menuTitle"
                                [readonly]="!product || project?.locked" [(ngModel)]="product && product.menuTitle" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.folder" appearance="fill">
                            <mat-label>{{project.fields.folder.label}} </mat-label>
                            <input matInput (focusout)="onFocusOutEvent($event)" name="folder"
                                [(ngModel)]="product && product.folder" [readonly]="!product || project?.locked" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.shortTitle" appearance="fill">
                            <mat-label>{{project.fields.shortTitle.label}} </mat-label>
                            <input matInput (focusout)="onFocusOutEvent($event)" name="mouseover"
                                [(ngModel)]="product && product.shortTitle" [readonly]="!product || project?.locked" />
                        </mat-form-field>
                        <mat-form-field  appearance="fill">
                            <mat-label>Hotspot ID </mat-label>
                            <input matInput (focusout)="onFocusOutEvent($event)" name="hotspotId"
                                [readonly]="!product || project?.locked" [(ngModel)]="product && product.hotspotId" />
                        </mat-form-field>
                    </mat-tab>
                    <mat-tab label="Resources">
                        <p></p>
                        <mat-form-field *ngIf="project?.fields?.pdf" appearance="fill">
                            <mat-label>{{project.fields.pdf.label}}</mat-label>
                            <input matInput name="PDF" (focusout)="onFocusOutEvent($event)" [readonly]="project?.locked"
                                [(ngModel)]="product && product.pdf" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.mainImage" appearance="fill">
                            <mat-label>{{project.fields.mainImage.label}}</mat-label>
                            <input [readonly]="!product || project?.locked" (focusout)="onFocusOutEvent($event)"
                                matInput name="mainImage" [(ngModel)]="product && product.mainImage" />
                        </mat-form-field>   
                        <mat-form-field *ngIf="project?.fields?.slide2" (focusout)="onFocusOutEvent($event)"
                            appearance="fill">
                            <mat-label>{{project.fields.slide2.label}}</mat-label>
                            <input matInput name="slide2" [(ngModel)]="product && product.slide2"
                                [readonly]="!product || project?.locked" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.slide3" (focusout)="onFocusOutEvent($event)"
                            appearance="fill">
                            <mat-label>{{project.fields.slide3.label}}</mat-label>
                            <input matInput name="slide3" [(ngModel)]="product && product.slide3"
                                [readonly]="!product || project?.locked" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.slide4" (focusout)="onFocusOutEvent($event)"
                            appearance="fill">
                            <mat-label>{{project.fields.slide4.label}}</mat-label>
                            <input matInput name="slide4" [(ngModel)]="product && product.slide4"
                                [readonly]="!product || project?.locked" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.slide5" (focusout)="onFocusOutEvent($event)"
                            appearance="fill">
                            <mat-label>{{project.fields.slide5.label}}</mat-label>
                            <input matInput name="slide5" [(ngModel)]="product && product.slide5"
                                [readonly]="!product || project?.locked" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.slide6" (focusout)="onFocusOutEvent($event)"
                            appearance="fill">
                            <mat-label>{{project.fields.slide6.label}}</mat-label>
                            <input matInput name="slide6" [(ngModel)]="product && product.slide6"
                                [readonly]="!product || project?.locked" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.slide7" (focusout)="onFocusOutEvent($event)"
                            appearance="fill">
                            <mat-label>{{project.fields.slide7.label}}</mat-label>
                            <input matInput name="slide7" [(ngModel)]="product && product.slide7"
                                [readonly]="!product || project?.locked" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.slide8" (focusout)="onFocusOutEvent($event)"
                            appearance="fill">
                            <mat-label>{{project.fields.slide8.label}}</mat-label>
                            <input matInput name="slide8" [(ngModel)]="product && product.slide8"
                                [readonly]="!product || project?.locked" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.slide9" (focusout)="onFocusOutEvent($event)"
                            appearance="fill">
                            <mat-label>{{project.fields.slide9.label}}</mat-label>
                            <input matInput name="slide9" [(ngModel)]="product && product.slide9"
                                [readonly]="!product || project?.locked" />
                        </mat-form-field>
                        <mat-form-field *ngIf="project?.fields?.slide10" (focusout)="onFocusOutEvent($event)"
                            appearance="fill">
                            <mat-label>{{project.fields.slide10.label}}</mat-label>
                            <input matInput name="slide10" [(ngModel)]="product && product.slide10"
                                [readonly]="!product || project?.locked" />
                        </mat-form-field>
                    </mat-tab>
                    <mat-tab label="Admin" *ngIf="authService.getCurrentUser().role=='admin'" tabTitle="Admin">
                        <p></p>
                        <div class="container">
                            <mat-form-field appearance="fill" class="item2">
                                <mat-label>Ref</mat-label>
                                <input matInput (focusout)="onFocusOutEvent($event)"
                                    (focusout)="onFocusOutEvent($event)" name="ref" [(ngModel)]="product && product.ref"
                                    [readonly]="!product || project?.locked" />
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="item2">
                                <mat-label>Document Id</mat-label>
                                <input matInput (focusout)="onFocusOutEvent($event)"
                                    (focusout)="onFocusOutEvent($event)" name="id" [(ngModel)]="product && product._id"
                                    readonly />
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="fill">
                            <mat-label>Info Panel Html</mat-label>
                            <textarea matInput readonly name="infoPanelHtml" rows="20"
                                [(ngModel)]="product && product.html"></textarea>
                        </mat-form-field>
                        <p></p>
                        <div class="container">
                            <mat-form-field appearance="fill" class="item2">
                                <mat-label>Height</mat-label>
                                <input matInput name="height" [(ngModel)]="product && product.height"
                                    [readonly]="!product || project?.locked" (focusout)="onFocusOutEvent($event)" />
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="item2">
                                <mat-label>Width</mat-label>
                                <input matInput name="width" [(ngModel)]="product && product.width"
                                    [readonly]="!product || project?.locked" (focusout)="onFocusOutEvent($event)" />
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="item2">
                                <mat-label>Logo Align</mat-label>
                                <input matInput name="LogoAlign" [(ngModel)]="product && product.logoAlign"
                                    [readonly]="!product || project?.locked" (focusout)="onFocusOutEvent($event)" />
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="item2">
                                <mat-label>Padding</mat-label>
                                <input matInput name="padding" [(ngModel)]="product && product.padding"
                                    [readonly]="!product || project?.locked" (focusout)="onFocusOutEvent($event)" />
                            </mat-form-field>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </form>
        </div>
        <div *ngIf="!isCollapsed" class="history">
            <div [innerHTML]="history()"></div>
        </div>
    </div>
</span>