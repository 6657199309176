<!-- Highlight Card -->

<link rel="stylesheet"
    href="https://unpkg.com/@ag-grid-community/all-modules@25.3.0/dist/styles/ag-theme-material.css" />
<link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />

<ag-grid-angular #agGrid 
    style="height: 90%; width: 100%"
    class="ag-theme-material" 
    rowSelection="single"
    rowHeight="38"
    [rowDragManaged]="true" 
    [animateRows]="true" 
    [columnDefs]="columnDefs"
    (rowSelected)="onRowSelected($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    (firstDataRendered)="onFirstDataRendered($event)"
    [frameworkComponents]="frameworkComponents">
</ag-grid-angular>