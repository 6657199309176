import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AppSettingsService } from "../shared/appSettings.service";
import {
  MatRadioModule,
  MatRadioGroup,
  MatRadioButton,
} from "@angular/material/radio";
import { MatDialogRef } from "@angular/material/dialog";
import { AppSettings } from "../shared/AppSettings";
import { MatRadioChange } from "@angular/material/radio";
import { AuthService } from "../services/auth.service";
import { ProjectService } from "../services/project.service";
import { Project } from "../data/project";

@Component({
  selector: "app-project-settings-modal",
  templateUrl: "./project-settings-modal.component.html",
  styleUrls: ["./project-settings-modal.component.css"],
})
export class ProjectSettingsModalComponent implements OnInit {
  public appSettings: AppSettings;

  public projects: Project[];

  constructor(
    private appSettingsService: AppSettingsService,
    private authService: AuthService,
    private projectService: ProjectService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ProjectSettingsModalComponent>
  ) {
    this.appSettings = this.appSettingsService.getSettings();

    const user = this.authService.getCurrentUser();

    this.projectService.getProjects().subscribe({
      next: (data) => {
        console.log(data);

        this.projects = data.sort((a, b) => a.project.localeCompare(b.project));
      },
      error: (error) => {
        this.toastr.error("Project list could not be fetched", "Server Error");

        console.error(error);
      },
    });
  }

  radioChange(event: MatRadioChange) {
    console.log('new project is ' + event.value.project);
    this.appSettings.activeProject = event.value;
    this.appSettings.currentProductCollection = event.value.productCollection;
    this.dialogRef.close(event.value);
  }

  ngOnInit(): void {}
}


