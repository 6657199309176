// app/button-cell-renderer.component.ts

import { Component } from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'menu-order-cell-renderer',
  template: `
      <div> {{this.displayedValue}}</div>
    `,
})
export class MenuOrderCellRenderer {
  refresh(params: ICellRendererParams): boolean {
    throw new Error("Method not implemented.");
  }

  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  isExcluded() : boolean {
    return this.params.value == 999;
  }

  public get displayedValue(){
    if (this.params.value !== 999){
      return this.params.value;
    }

  }

  ngOnDestroy() {
    // no need to remove the button click handler 
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}