// app/button-cell-renderer.component.ts

import { Component } from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'btn-delete-cell-renderer',
    template: `
      <button (click)="btnClickedHandler($event)"><mat-icon>delete</mat-icon></button>
    `,
    styleUrls: ["./button-renderer.styles.css"],
  })
  export class BtnDeleteCellRenderer {
    refresh(params: ICellRendererParams): boolean {
        throw new Error("Method not implemented.");
    }

    private params: any;
  
    agInit(params: any): void {
      this.params = params;
    }
  
    btnClickedHandler($event) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      }
      this.params.onClick(this.params);
    }
  
    ngOnDestroy() {
      // no need to remove the button click handler 
      // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
  }