import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { AgGridModule } from "ag-grid-angular";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ProductEditFormComponent } from "./product-edit-form/product-edit-form.component";
import { ProductGridComponent } from "./product-grid/product-grid.component";
import { TabModule } from "angular-tabs-component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

import { AppSettingsService } from "./shared/appSettings.service";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";

import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs"
import { MatMenuModule } from "@angular/material/menu";
import {MatDividerModule} from '@angular/material/divider';

import { MatDialogModule } from "@angular/material/dialog";
import { ProjectSettingsModalComponent } from "./project-settings-modal/project-settings-modal.component";
import { MatRadioModule } from "@angular/material/radio";
import { LoginComponent } from "./login/login.component";
import { AppRoutingModule } from "./app-routing.module";
import { ProductMainComponent } from "./product-main/product-main.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { httpInterceptorProviders } from "./http-interceptors";

import { FlexLayoutModule } from "@angular/flex-layout";
import { FileUploadModalComponent } from "./file-upload-modal/file-upload-modal.component";

// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { ProductPreviewComponent } from './product-preview/product-preview.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { GlobalErrorHandler } from "./errors/global-error-handler";
import { HttpLoadingInterceptor } from "./errors/http-loading.interceptor";
import { BtnDeleteCellRenderer } from "./product-grid/button-delete-cell-renderer";
import { BtnEnableCellRenderer } from "./product-grid/button-enable-cell-renderer";
import { MenuOrganizerComponent } from './menu-organizer/menu-organizer.component';


@NgModule({
  imports: [
    // Angular modules
    BrowserModule,
    FormsModule,

    FlexLayoutModule,
    HttpClientModule,
    TabModule,
    BrowserAnimationsModule, // required animations module
    FontAwesomeModule,
    LMarkdownEditorModule,

    // material design modules
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatDividerModule,
    MatFormFieldModule,
    DragDropModule,
    NgxSpinnerModule,

    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }), // ToastrModule added
    AgGridModule.withComponents([BtnDeleteCellRenderer, BtnEnableCellRenderer]),

    // Routing modules always go at the bottom of the import list
    AppRoutingModule,
  ],
  declarations: [
    AppComponent,
    ProductEditFormComponent,
    ProductGridComponent,
    ProjectSettingsModalComponent,
    BtnDeleteCellRenderer,
    BtnEnableCellRenderer,
    LoginComponent,
    ProductMainComponent,
    FileUploadModalComponent,
    ProductPreviewComponent,
    MenuOrganizerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  providers: [AppSettingsService, httpInterceptorProviders]
 ,
  entryComponents: [ProjectSettingsModalComponent, FileUploadModalComponent],
})
export class AppModule {}
