<h1 mat-dialog-title>Project File Upload</h1>

<div>
  <p class="warning">
    Warning. Uploading a project file will replace the entire contents of the
    current project.
    <br>Ensure you have taken a backup first!
  </p>
  <input
    type="file"
    class="file-input"
    (change)="onFileSelected($event)"
    #fileUpload
  />

  <div class="file-upload">
    {{ fileName || "No file selected" }}

    <button
      mat-mini-fab
      color="primary"
      (click)="fileUpload.click()"
    >
      <mat-icon>attach_file</mat-icon>
    </button>
  </div>
  <div>
    <button
      ng
      mat-raised-button
      color="primary"
      (click)="onClickUpload()"
      class="upload-file-btn"
      *ngIf="file"
    >
      Upload
    </button>
  </div>

</div>
