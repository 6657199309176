import { Project } from "../data/project";

export class AppSettings {
  projects: Project[];
  activeProject : Project;

  get currentProductCollection(): string {
    let proj = localStorage.getItem("cms-current-project-collection");

    if (!proj) {
      proj = "Products_Testing_EN";
    }

    return proj;
  }

  set currentProductCollection(value: string) {
    localStorage.setItem("cms-current-project-collection", value);
  }

  productRefTemplate = "product";


 //baseUrl = "http://localhost:1337/api";

 //baseUrl = "https://cms-api-staging.azurewebsites.net/api";
 baseUrl = "https://cms-api-production.azurewebsites.net/api";

}
