import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ProductService } from "../services/product.service";

@Component({
  selector: "app-file-upload-modal",
  templateUrl: "./file-upload-modal.component.html",
  styleUrls: ["./file-upload-modal.component.css"],
})
export class FileUploadModalComponent implements OnInit {
  constructor(
    private productService: ProductService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<FileUploadModalComponent>
  ) {}

  fileName = "";
  file: File;

  ngOnInit(): void {}

  onClickUpload() {
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const json = fileReader.result;

      let products: any;
      try{
        products = JSON.parse(json as string);
      } catch(e) {
        this.toastr.error("The selected file does not contain valid json. Please check.");
        this.file = null;
        this.fileName = '';
        return;
      }
      this.productService.uploadProducts(products).subscribe((x: any) => {
        this.dialogRef.close(true);
      });
    };
    fileReader.readAsText(this.file);
  }

  onFileSelected(event) {
    const file = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      this.file = file;
    }
  }
}
