// app/button-cell-renderer.component.ts

import { Component } from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'btn-enable-cell-renderer',
  template: `
      <button *ngIf="!isDisabled()" (click)="btnClickedHandler($event)"><mat-icon>check_box</mat-icon></button>
      <button *ngIf="isDisabled()" (click)="btnClickedHandler($event)"><mat-icon>check_box_outline_blank</mat-icon></button>
    `,
  styleUrls: ["./button-renderer.styles.css"],
})
export class BtnEnableCellRenderer {
  refresh(params: ICellRendererParams): boolean {
    throw new Error("Method not implemented.");
  }

  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  isDisabled() : boolean {
    return this.params.node.data?.disabled;
  }

  btnClickedHandler($event) {
    const params = {
      event: $event,
      rowData: this.params.node.data
    }
    this.params.onClick(this.params);
  }

  ngOnDestroy() {
    // no need to remove the button click handler 
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}