<div class="container">
    <div class="item listPanel">
        <app-product-grid
        (selectedItemEvent)="onItemSelected($event)"
        (productDeleted)="onProductDeleted($event)"
        (productStatusToggled)="onProductStatusToggled($event)"
        (previousProductDirty)="onPreviousProductDirty($event)"
        (gridUpdated)="onProductUpdated($event)"

        [project]="project"
      ></app-product-grid>
      <p></p>
      <button *ngIf="product" mat-raised-button color="primary" (click)="onPreviewProduct()" >Preview</button>
      <button mat-raised-button color="primary" *ngIf="!project?.locked"  (click)="onAddProduct()">Add</button> 
      <button mat-raised-button color="primary" *ngIf="product && !project?.locked"  (click)="onCloneProduct()">Duplicate</button> 
      <button mat-raised-button color="primary" *ngIf="!project?.locked" [disabled]="!isDirty"  (click)="onSave()">Save</button> 
    </div>
    <!-- The resizer -->
    <div class="resizer" id="dragMe"></div>
    <div class="item editPanel">
      <app-product-edit-form
        [product]="product"
        [project]="project"
        [dirty]="isDirty"
        (productUpdated)="onProductUpdated($event)"
        (previousProductDirty)="onPreviousProductDirty($event)"
        (collapseForm)="onFormCollapse($event)"
      ></app-product-edit-form>
    </div>
</div>
